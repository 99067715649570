import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NavbarService } from '../navbar/navbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private navbarService: NavbarService,
    private router: Router,
    private spinner: SpinnerService,
    private store: Store,
  ) {}
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.navbarService.setActiveSidebar(false);

    setTimeout(() => {
      // redirect to home if already logged in
      const role = this.store.selectSnapshot<string>((s) => {
        return s.auth.role;
      });
      if (role) {
        if (role.toLowerCase() === 'general') {
          this.router.navigate(['/memos']);
        } else if (role.toLowerCase() === 'contract management') {
          this.router.navigate(['/memos']);
        } else {
          this.router.navigate(['/users']);
        }
        this.navbarService.setActiveSidebar(true);
      } else {
        this.router.navigate(['/login']);
      }
    }, 1500);
  }
}
